
export const ModuleList = [
    {
      key: 'newMaterialMaster',
      name: 'Product (BOM)'
    },
    {
        key: "productionBatch",
        name: "Production Batch"
    },
    {
        key: "productionBatchInput",
        name: "Production Batch Input"
    },
    {
        key: "productionBatchOutput",
        name: "Production Batch Output"
    },
    {
        key: "materialDetails",
        name: "Material Details"
    },
    {
        key: "materialMaster",
        name: "Material Master"
    },
    {
        key: "materialQualityParameter",
        name: "Material Quality Parameter"
    },
    {
        key: "purchaseOrder",
        name: "Purchase Order"
    },
    {
        key: "department",
        name: "Department"
    },
    {
        key: "machine",
        name: "Machine"
    },
    {
        key: "purchaseOrderDetails",
        name: "Purchase Order Details"
    },
    {
        key: "salesOrder",
        name: "Sales Order"
    },
    {
        key: "salesOrderDetails",
        name: "Sales Order Details"
    },
    {
        key: "party",
        name: "Party"
    },
    {
        key: "partyContactDetails",
        name: "Party Contact Details"
    },
    {
        key: "bom",
        name: "BOM"
    },
    {
        key: "bomMaterialDetails",
        name: "BOM Material Details"
    },
    {
        key: "bomQualityParametersDetails",
        name: "BOM Quality Parameters Details"
    },
    {
        key: "bomCostHeadDetails",
        name: "BOM Cost Head Details"
    },
    {
        key: "bomDocumentDetails",
        name: "BOM Document Details"
    },
    {
        key: "materialTypes",
        name: "Material Types"
    },
    {
        key: "supportingMaterial",
        name: "Supporting Material"
    },
    {
        key: "costHead",
        name: "Cost Head"
    },
    {
        key: "process",
        name: "Process"
    },
    {
        key: "productGroup",
        name: "productGroup"
    },
    {
        key: "purity",
        name: "Purity"
    },
    {
        key: "ph",
        name: "PH"
    },
    {
        key: "moisture",
        name: "Moisture"
    },
    {
        key: "solubility",
        name: "Solubility"
    },
    {
        key: "funnel",
        name: "Funnel"
    },
    {
        key: "funnelProductDetails",
        name: "Funnel Product Details"
    },
    {
        key: "funnelLogActivity",
        name: "Funnel Log Activity"
    },
    {
        key: "transport",
        name: "Transport"
    },
    {
        key: "custom_field",
        name: "Custom Field"
    },
    {
        key: "custom_parameters",
        name: "Custom Parameters"
    },
    {
        key: "custom_module_field",
        name: "Custom Module Field"
    },
    {
        key: "funnelStage",
        name: "Funnel Stage"
    },
    {
        key: "crmActivity",
        name: "CRM Activity"
    },
    {
        key: "labTest",
        name: "Lab Test"
    },
    {
        key: "smInward",
        name: "Supporting Material Inward"
    },
    {
        key: "smAllocation",
        name: "Supporting Material Allocation"
    },
    {
        key: "materialRequest",
        name: "Material Request"
    },
    {
        key: "quotation",
        name: "Quotation"
    },
    {
        key: "quotationProductDetails",
        name: "Quotation Product Details"
    },
    {
        key: "planning",
        name: "Planning"
    },
    {
        key: "dispatch",
        name: "Dispatch"
    },
    {
        key: "dispatchDetails",
        name: "Dispatch Details"
    },
    {
        key: "dispatchCostHeadDetails",
        name: "Dispatch CostHead Details"
    },
    {
        key: "dispatchSupportingMaterialDetails",
        name: "Dispatch Supporting Material Details"
    },
    {
        key: "inwardFoundary",
        name: "Inward Foundry"
    },
    {
        key: "inwardFoundaryDetails",
        name: "Inward Foundry Details"
    },
    {
        key: "inward",
        name: "Inward"
    },
    {
        key: "inwardDetails",
        name: "Inward Details"
    },
    {
        key: "packingTypeInward",
        name: "Packing Type Inward"
    },
    {
        key: "packingTypeInwardDetails",
        name: "Packing Type Inward Details"
    },
    {
        key: "vendorTransactionDetails",
        name: "Vendor Transaction Details"
    },
    {
        key: "vendorTransactionProcessedInput",
        name: "Vendor Transaction Processed Input"
    },
    {
        key: "vendorTransactionDispatchDetails",
        name: "Vendor Transaction Dispatch Details"
    },
    {
        key: "vendorTransactionTransferBack",
        name: "Vendor Transaction Transfer Back"
    },
    {
        key: "user",
        name: "User"
    },
    {
        key: "dailyProduction",
        name: "Daily Production"
    },
    {
        key: "dailyProductionInputDetails",
        name: "Daily Production Input Details"
    },
    {
        key: "dailyProductionOtherOutputDetails",
        name: "Daily Production Other Output Details"
    },
    {
        key: 'categoryDetail',
        name: 'Category Master'
    },
    {
        key: 'subCategoryDetail',
        name: 'Sub Category Details'
    },
    {
        key: 'brand',
        name: 'Brand Master'
    },
    {
     key: 'currency',
     name: 'Currency (Master)'   
    },
    {
     key: 'priceType',
     name: 'Price Type (Master)'
    },
    {
     key: 'categoryType',
     name: 'Category Type (Master)'
    },
    {
     key: 'newCategory',
     name: 'New Category (Master)'   
    },
    {
        key: 'uom',
        name: 'Uom (Master)'
    },
    {
        key: 'GST',
        name: 'GST (Master)'
    },
    {
        key: 'Basic OH',
        name: 'Basic OH (Master)'
    },
    {
        key: 'customerType',
        name: 'Customer Type (Master)'
    },
    {
        key: 'retail',
        name: 'Retail Cost (Master)'
    },
    {
        key: 'btbimages',
        name: 'BtB Images (Master)'
    }
];

export class ModuleListData {
}
