import {ModuleParamGlobConfigService} from "../../../core/service/moduleFieldConfig/module-param-glob-config.service";

export class userModuleParamConfigModel {

    constructor(private moduleParamConfigGlob : ModuleParamGlobConfigService,) {
    }

    paramData = {
        first_name: {
            label: this.moduleParamConfigGlob.getLabel("user","first_name","First Name"),
        },
        last_name: {
            label: this.moduleParamConfigGlob.getLabel("user","last_name","Last Name"),
        },
        email: {
            label: this.moduleParamConfigGlob.getLabel("user","email","Email"),
        },
        username: {
            label: this.moduleParamConfigGlob.getLabel("user","username","Username"),
        },
        phone: {
            label: this.moduleParamConfigGlob.getLabel("user","phone","Phone"),
        },
        password: {
            label: this.moduleParamConfigGlob.getLabel("user","password","Password"),
        },
        address: {
            label: this.moduleParamConfigGlob.getLabel("user","address","Address"),
        },
        role: {
            label: this.moduleParamConfigGlob.getLabel("user","role","Role"),
        },
        manager: {
            label: this.moduleParamConfigGlob.getLabel("user","manager","Manager"),
            isVisible: this.moduleParamConfigGlob.isVisible("user","manager"),
            isRequired: this.moduleParamConfigGlob.isRequired("user","manager")
        },
        department: {
            label: this.moduleParamConfigGlob.getLabel("user","department","Department"),
        },
    }

}
