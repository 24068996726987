import { Injectable } from '@angular/core';
import {BaseService} from "../../../core/service/base-service";
import {HttpClient} from "@angular/common/http";
import {AuthUtilsService} from "../../../core/util/auth-utils.service";
import {RFQCreationModel} from "../model/rfqcreation-model";
import {PaginationData} from "../../../core/modal/pagination-data";
import {OrderingData} from "../../../core/modal/ordering-data";
import {Observable} from "rxjs";
import {ResponseData} from "../../../core/modal/base-save-update-response";
import {QuotationModel} from "../../quotation/model/quotation-model";
import {environment} from "../../../../environments/environment";
import {QuotationMailModel} from "../../quotation/model/quotation-mail-model";
import {SalesOrderModal} from "../../sales-order/modal/sales-order-modal";

@Injectable({
  providedIn: 'root'
})
export class RFQCreationService extends BaseService<RFQCreationModel> {


  constructor(
      http: HttpClient,
      authService: AuthUtilsService
  ) { super(http, authService); }

  getModuleName(): string {
    return 'crm/quotation';
  }

  validate(): boolean {
    return false;
  }

  getAll(paginationData: PaginationData, orderingData: OrderingData): Observable<ResponseData<RFQCreationModel>> {
    return this.http.get<ResponseData<RFQCreationModel>>(environment.SERVER_URL + 'crm/quotation/getQuotationByLoggedInUser');
  }

  public sendMail(mailDto: QuotationMailModel) {
    return this.http.post<ResponseData<QuotationMailModel>>(environment.SERVER_URL + 'crm/quotation/sendMail', mailDto);
  }

  public generateSalesOrder(data: SalesOrderModal): Observable<ResponseData<SalesOrderModal>> {
    return this.http.post<ResponseData<SalesOrderModal>>(environment.SERVER_URL + 'chemical/salesOrder/save' , data);
  }

  public updateQuotation(event: QuotationModel): Observable<ResponseData<QuotationModel>> {
    return this.http.post<ResponseData<QuotationModel>>(environment.SERVER_URL + 'crm/quotation/update', event);
  }

}
