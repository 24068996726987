import {PartyModal} from "../../party/modal/party-modal";
import {SoDetailModal} from "./so-detail-modal";
import {PurchaseOrderModal} from '../../purchase-order/modal/purchase-order';

export class SalesOrderModal {
    id: number;
    soNumber: string;
    party: PartyModal;
    remark: string;
    name: string;
    date: Date;
    salesOrderDetails: Array<SoDetailModal>;
    quotation: number;
    quotationNo: string;
    generatedFrom: string;
    poReference: string;

    customerExpectedDate: Date;
    tentativeCompletionDate: number;
    actualCompletionDate: number;

    // For Angular Use
    dateString: string;
    partyString: string;
    moduleName: string;
    tentativeCompletionDateFormat: Date;
    actualCompletionDateFormat: Date;
}
