import {FunnelModal} from "../../funnel/modal/funnel-modal";
import {PartyModal} from "../../party/modal/party-modal";
import {PartyDetailModal} from "../../party/modal/party-detail-modal";
import {UserModal} from "../../user/modal/user-modal";
import {QuotationDetailModel} from "../../quotation/model/quotation-detail-model";

export class RFQCreationModel {

    id: number;
    quotationNo: string;
    project: FunnelModal;
    customer: PartyModal;
    customerRepresentative: PartyDetailModal;
    createdDate: Date = new Date();
    totalAmount: number;
    termsOfDelivery = 'Delivery: within 7-8 days after receipt of your confirm order';
    termsOfFor: string;
    termsOfTax = 'Tax: As applicable 18% GST extra';
    termsOfPayment = 'Payment: 30 Days';
    termsOfOther: string;
    createdBy: UserModal;
    status = false;

    details: Array<QuotationDetailModel>;

    // Angular Use
    date: string;
    projectName: string;
    customerName: string;
    staff: string;
    totalAmt: string;
    totalDiscount: number;
    totalFinalAmt: number;
    generateOrder: string;
    statusName: string;
    moduleName: string;
    lastDate: Date = new Date();

}
