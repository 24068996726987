export class InwardFoundaryAdditionDetails {
    receivedMaterialBagNumber: number;
    receivedMaterialBagWeight: number;
    partyMaterialBagWeight: number;
    dustLineWeight: number;
    receivedMaterialOtherDeduction: number



    id: number;
    partyGrossWeight: number;
    partyTareWeight: number;
    partyAvgQuantity: number;
    partyNetPrintBag: number;
    receivedGrossWeight = 0;
    receivedTareWeight = 0;
    receivedAvgQuantity = 0;
    receivedNetPrintBag: number;
}
