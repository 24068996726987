import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AuthUtilsService} from "../../../core/util/auth-utils.service";
import {BaseService} from "../../../core/service/base-service";
import {DispatchModal} from "../modal/dispatch-modal";
import {Observable} from "rxjs";
import {ResponseData} from "../../../core/modal/base-save-update-response";
import {environment} from "../../../../environments/environment";
import {DispatchDetailsModal} from "../modal/dispatch-details-modal";
import {Organization} from "../../purchase-order/modal/organization";

@Injectable({
  providedIn: 'root'
})
export class DispatchServiceService extends BaseService<DispatchModal >{

  constructor(
      http: HttpClient,
      authService: AuthUtilsService
  ) {
    super(http, authService);
  }

  getModuleName(): string {
    return "chemical/dispatch";
  }

  validate(): boolean {
    return false;
  }

  public getProduct(): Observable<ResponseData<DispatchDetailsModal>> {
    return this.http.get<ResponseData<DispatchDetailsModal>>(environment.SERVER_URL + 'chemical/itemStock');
  }

  public getOrganizationList(): Observable<ResponseData<Organization>> {
    return this.http.get<ResponseData<Organization>>(environment.SERVER_URL + 'organization' + 'getAllByPartner');
  }
}
