import {ModuleParamGlobConfigService} from "../../../core/service/moduleFieldConfig/module-param-glob-config.service";

export class quotationModuleParamConfigModel {

    constructor(private moduleParamConfigGlob : ModuleParamGlobConfigService,) {
    }

    paramData = {
        project_name: {
            label: this.moduleParamConfigGlob.getLabel("quotation","project_name","Project Name"),
        },
        date: {
            label: this.moduleParamConfigGlob.getLabel("quotation","date","Date"),
        },
        quotation_number: {
            label: this.moduleParamConfigGlob.getLabel("quotation","quotation_number","Quotation Number"),
        },
        customer_name: {
            label: this.moduleParamConfigGlob.getLabel("quotation","customer_name","Customer Name"),
        },
        customer_representative: {
            label: this.moduleParamConfigGlob.getLabel("quotation","customer_representative","Customer Representative"),
        },
        staff_name: {
            label: this.moduleParamConfigGlob.getLabel("quotation","staff_name","Staff Name"),
        },
        terms_of_delivery: {
            label: this.moduleParamConfigGlob.getLabel("quotation","terms_of_delivery","Terms Of Delivery"),
            isVisible: this.moduleParamConfigGlob.isVisible("quotation","terms_of_delivery"),
        },
        terms_of_for: {
            label: this.moduleParamConfigGlob.getLabel("quotation","terms_of_for","Terms Of For"),
            isVisible: this.moduleParamConfigGlob.isVisible("quotation","terms_of_for"),
        },
        terms_of_tax: {
            label: this.moduleParamConfigGlob.getLabel("quotation","terms_of_tax","Terms Of Tax"),
            isVisible: this.moduleParamConfigGlob.isVisible("quotation","terms_of_tax"),
        },
        terms_of_other: {
            label: this.moduleParamConfigGlob.getLabel("quotation","terms_of_other","Terms Of Other"),
            isVisible: this.moduleParamConfigGlob.isVisible("quotation","terms_of_other"),
        },
        terms_of_payment: {
            label: this.moduleParamConfigGlob.getLabel("quotation","terms_of_payment","Terms Of Payment"),
            isVisible: this.moduleParamConfigGlob.isVisible("quotation","terms_of_payment"),
        },
        total_amount: {
            label: this.moduleParamConfigGlob.getLabel("quotation","total_amount","Total Amount"),
            isVisible: this.moduleParamConfigGlob.isVisible("quotation","total_amount"),
        },
        discounted_amount: {
            label: this.moduleParamConfigGlob.getLabel("quotation","discounted_amount","Discounted Amount"),
            isVisible: this.moduleParamConfigGlob.isVisible("quotation","discounted_amount"),
        },
        final_amount: {
            label: this.moduleParamConfigGlob.getLabel("quotation","final_amount","Final Amount"),
            isVisible: this.moduleParamConfigGlob.isVisible("quotation","final_amount"),
        }
    }

    quotationProductDetails = {
        material: {
            label: this.moduleParamConfigGlob.getLabel("quotationProductDetails","material","Material"),
        },
        final_amount: {
            label: this.moduleParamConfigGlob.getLabel("quotationProductDetails","final_amount","Final Amount"),
        },
        weight: {
            label: this.moduleParamConfigGlob.getLabel("quotationProductDetails","weight","Weight"),
        },
        grade: {
            label: this.moduleParamConfigGlob.getLabel("quotationProductDetails","grade","Grade"),
        },
        material_price: {
            label: this.moduleParamConfigGlob.getLabel("quotationProductDetails","material_price","material_price"),
        },
        total_amount: {
            label: this.moduleParamConfigGlob.getLabel("quotationProductDetails","total_amount","Total Amount"),
        },
        discounted_amount: {
            label: this.moduleParamConfigGlob.getLabel("quotationProductDetails","discounted_amount","Discounted Amount"),
        },
        color: {
            label: this.moduleParamConfigGlob.getLabel("quotationProductDetails","color","Color"),
            isVisible: this.moduleParamConfigGlob.isVisible("quotationProductDetails","color"),
        },
        size: {
            label: this.moduleParamConfigGlob.getLabel("quotationProductDetails","size","Size"),
            isVisible: this.moduleParamConfigGlob.isVisible("quotationProductDetails","size"),
        },
        discount: {
            label: this.moduleParamConfigGlob.getLabel("quotationProductDetails","discount","Discount"),
            isVisible: this.moduleParamConfigGlob.isVisible("quotationProductDetails","discount"),
        },
        filler: {
            label: this.moduleParamConfigGlob.getLabel("quotationProductDetails","filler","Filler"),
            isVisible: this.moduleParamConfigGlob.isVisible("quotationProductDetails","filler"),
        }
    }

}
