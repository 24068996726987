import {MoistureModal} from "../../moisture/modal/moisture-modal";
import {PHModal} from "../../ph/modal/phmodal";
import {Puritymodal} from "../../purity/modal/puritymodal";
import {SolubilityModal} from "../../solubility/modal/solubility-modal";
import {ProductModal} from "../../product/modal/product-modal";
import {InwardFoundaryAdditionDetails} from "../../inward-foundary/modal/inward-foundary-addition-details";

export class InwardDetailModal {
    id: number;
    isPass = true;
    partyMoisture: MoistureModal;
    partyPh: PHModal;
    partyPrice:	number;
    partyPurity: Puritymodal;
    partySolubility: SolubilityModal;
    partyWeight: number;
    partySecondaryQuantity: number;
    partyRollNo: string;

    productName: ProductModal;
    receivedMoisture: MoistureModal;
    receivedPh: PHModal;
    receivedPrice: number;
    receivedPurity:	Puritymodal;
    receivedSolubility:	SolubilityModal;
    receivedWeight:	number;
    receivedSecondaryQuantity: number;
    receivedRollNo: string;
    totalPrice:	number;
    vendorCode: string;

    additionalDetails: InwardFoundaryAdditionDetails;
    isSelected: boolean;
}
